import React, { useRef, useEffect } from "react";
import { useMasterContext } from '../components/MasterContext';
import { CustomSelect } from '../components/CustomSelect';
import { PortfolioData } from "../data/PortfolioData";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import emailjs from 'emailjs-com';
import { Helmet } from "react-helmet";
import $ from 'jquery';

export const Home = () => {
    const form = useRef();
    const { setloader } = useMasterContext();
    const navigate = useNavigate();

    useEffect(() => {
        $('.form-control').each(function () {
            $(this).toggleClass("valid", this.value !== "");
        });
        $('.form-control').change(function () {
            $(this).toggleClass("valid", this.value !== "");
        });
    }, []);

    const validateForm = () => {
        let isInvalid = null;
        $('.InptFld input').each(function () {
            if ($(this).val().trim() === '') {
                isInvalid = this;
                return false;
            }
        });

        const invalidLabel = isInvalid ? $(isInvalid).siblings('label').text() : null;
        return { isInvalid, invalidLabel };
    };

    const sendEmail = (e) => {
        e.preventDefault();
        const { isInvalid, invalidLabel } = validateForm();
        if (isInvalid === null) {
            setloader(true);
            emailjs.sendForm('service_4uu6zlq', 'template_ykjlkik', form.current, 'ndVVqni1uJxBnHQF4')
                .then((result) => {
                    form.current.reset();
                    $('.InptFld input,textarea').each(function () {
                        $(this).removeClass('valid');
                    });
                    // Navigate to the thank you page with the formSubmitted state
                    navigate('/thank-you', { state: { formSubmitted: true } });
                    setloader(null);
                })
                .catch((error) => {
                    $('#form1 .btn').after(`<p class="message">Something Went Wrong!</p>`);
                    setloader(null);
                });
        } else {
            $(isInvalid).focus();
            alert(`Please enter ${invalidLabel}`);
        }
    };

    return (
        <>
            <Helmet>
                <title>CODEKMR</title>
                <meta name='description' content='codekmr portfolio featuring innovative web development projects, responsive designs, and effective backend solutions. Explore a collection of successful projects demonstrating expertise in frontend and backend development, API integration, and SEO strategies.'/>
                <meta name='keywords' content='web development, responsive design, frontend development, backend solutions, API integration, SEO strategies, portfolio, CODEKMR' />
            </Helmet>
            <main>
                <div className="banner home-banner">
                    <div className="container">
                        <div className="content">
                            <h1>Hello, I am<br /> Susheel Kumar</h1>
                            <p>I specialize in <b>building websites</b> that are visually stunning, intuitive to navigate, and optimized for speed, performance, and long-term success. My goal is to create seamless digital experiences that not only look great but also function flawlessly, driving engagement and achieving business objectives.</p>
                            <div className="btn">
                                <Link to={require('../assets/doc/Susheel-Kumar-resume.pdf')} target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16"><path fill="currentColor" d="M8 4.5A1.25 1.25 0 1 0 8 2a1.25 1.25 0 0 0 0 2.5"/><path fill="currentColor" d="M8 4.5c.597 0 1.13.382 1.32.949l.087.26a.22.22 0 0 1-.21.291h-2.39a.222.222 0 0 1-.21-.291l.087-.26a1.39 1.39 0 0 1 1.32-.949zm-3 4a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m.5 1.5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1z"/><path fill="currentColor" fill-rule="evenodd" d="M2.33 1.64c-.327.642-.327 1.48-.327 3.16v6.4c0 1.68 0 2.52.327 3.16a3.02 3.02 0 0 0 1.31 1.31c.642.327 1.48.327 3.16.327h2.4c1.68 0 2.52 0 3.16-.327a3 3 0 0 0 1.31-1.31c.327-.642.327-1.48.327-3.16V4.8c0-1.68 0-2.52-.327-3.16A3 3 0 0 0 12.36.33C11.718.003 10.88.003 9.2.003H6.8c-1.68 0-2.52 0-3.16.327a3.02 3.02 0 0 0-1.31 1.31m6.87-.638H6.8c-.857 0-1.44 0-1.89.038c-.438.035-.663.1-.819.18a2 2 0 0 0-.874.874c-.08.156-.145.38-.18.819c-.037.45-.038 1.03-.038 1.89v6.4c0 .857.001 1.44.038 1.89c.036.438.101.663.18.819c.192.376.498.682.874.874c.156.08.381.145.819.18c.45.036 1.03.037 1.89.037h2.4c.857 0 1.44 0 1.89-.037c.438-.036.663-.101.819-.18c.376-.192.682-.498.874-.874c.08-.156.145-.381.18-.82c.037-.45.038-1.03.038-1.89v-6.4c0-.856-.001-1.44-.038-1.89c-.036-.437-.101-.662-.18-.818a2 2 0 0 0-.874-.874c-.156-.08-.381-.145-.819-.18c-.45-.037-1.03-.038-1.89-.038" clip-rule="evenodd"/></svg> View Resume</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <section >
                    <div className="homeSecA">
                        <div className="container">
                            <div className="model-body">
                                <div className="colA">
                                    <div className="heading">
                                        <h3>Skillset <span>Spotlight</span></h3>
                                    </div>
                                    <div className="col">
                                        <span>Language</span>
                                        <div className="col-md-sec">
                                            <div className="col-md">
                                                <h4>HTML</h4>
                                                <progress value={95} max={100} />
                                            </div>
                                            <div className="col-md">
                                                <h4>CSS(LESS,SCSS,SASS)</h4>
                                                <progress value={95} max={100} />
                                            </div>
                                            <div className="col-md">
                                                <h4>JS</h4>
                                                <progress value={75} max={100} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <span>Libraries</span>
                                        <div className="col-md-sec">
                                            <div className="col-md">
                                                <h4>ReactJS</h4>
                                                <progress value={75} max={100} />
                                            </div>
                                            <div className="col-md">
                                                <h4>jQuery</h4>
                                                <progress value={95} max={100} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col">
                                        <span>Framework</span>
                                        <div className="col-md-sec">
                                            <div className="col-md">
                                                <h4>Next.js</h4>
                                                <progress value={65} max={100} />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col">
                                        <span>Backend</span>
                                        <div className="col-md-sec">
                                            <div className="col-md">
                                                <h4>Django</h4>
                                                <progress value={55} max={100} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="colB">
                                    <div className="heading"><h3>About <span>Me</span></h3></div>
                                    <div className="WrapCol">
                                        <div className="headS">
                                            <div className="icon"><img src={require('../assets/images/image.jpg')} alt="website designing company" /></div>
                                            <div className="ttl">
                                                <h4>Susheel Kumar</h4>
                                                <p>FrontEnd Developer</p>
                                            </div>
                                        </div>
                                        <div className="content">
                                            <p>From Gurugram, Haryana, India</p>
                                            {/* <div className="flex">
                                                <div className="colms">
                                                    <p><b>Education:</b></p>
                                                    <p>Bachelor of Science in Computer</p>
                                                    <p>DPG Degree College, Gurugram</p>
                                                    <p>2021</p>
                                                </div>
                                            </div> */}
                                            <ul>
                                                <li>Converted <b>PSD/Figma</b> designs into cross-browser-compatible HTML websites.</li>
                                                <li>Developed <b>responsive</b> websites that work well on all devices and screen sizes.</li>
                                                <li>Used JavaScript animation libraries like <b>GSAP</b> to make websites interactive and engaging.</li>
                                                <li>Optimized <b>React</b> applications for better performance, ensuring faster load times and smoother functionality.</li>
                                                <li>Connected <b>API</b>s for smooth communication between frontend and backend.</li>
                                                <li>Followed HTML best practices to ensure fast-loading websites and achieve high scores on <b>Google PageSpeed Insights</b>.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="homeSecB">
                        <div className="container">
                            <div className="heading">
                                <h3>Projects</h3>
                                <p>PSD/Figma to HTML</p>
                            </div>
                            <div className="gridWrap">
                                <div className="grid">
                                    {PortfolioData &&
                                        PortfolioData.map((portfolio) => {
                                            const { url, image, title,alt } = portfolio;
                                            return (
                                                <div className="col" key={title}>
                                                    <Link to={url} target="_blank">
                                                        <figure><img src={require(`../assets/images/project/${image}`)} alt={alt} /></figure>
                                                        <figcaption>{title}</figcaption>
                                                        <div className="web-link">
                                                            <button type="button"><img src={require(`../assets/icon/browser.png`)} alt="codekmr" /></button>
                                                        </div>
                                                    </Link>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="inquirySec">
                        <div className="container">
                            <div className="inquirySecwrap">
                                <div className="heading">
                                    <h3>Get in Touch </h3>
                                </div>
                                <form id='form1' ref={form} onSubmit={sendEmail}>
                                    <div className="InptFld">
                                        <div className="form-group">
                                            <input className="form-control" type="text" name="user_name" placeholder="Enter Your Name" autoComplete='off' required />
                                            <label>Name*</label>
                                        </div>
                                        <div className="form-group">
                                            <input className="form-control" type="email" name="user_email" placeholder="Enter Your Email" autoComplete="off" required />
                                            <label>Email*</label>
                                        </div>
                                        <div className="form-group">
                                            <input className='form-control' type="text" minLength="7" maxLength="15" placeholder='Enter Your Contact' name="user_contact" onChange={(e) => e.target.value = e.target.value.replace(/[^0-9+-]/g, '')} required autoComplete="off" />
                                            <label>Contact*</label>
                                        </div>
                                        <div className="form-group">
                                            <CustomSelect select="service" label="Where did you find my portfolio?" isrequired options={["Search Engine", "LinkedIn", "Instagram","Others"]} />
                                        </div>
                                        <div className="form-group message-bx flex100">
                                            <textarea className='form-control' name="user_message" maxLength="200" placeholder='Maximum Character 200' required />
                                            <label>Message*</label>
                                        </div>
                                    </div>
                                    <div className="btn"><input type="submit" value="Submit" /></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};
